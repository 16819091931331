<div class="stepper-content">
    <div class="title-container">
        <h2 class="mx-auto py-3 text-center">Descubre cuánto puede crecer tu futura pensión</h2>
    </div>

    <div class="content">
        <div class="d-flex align-items-center gap-4" style="padding: 0 60px;">
            <div class="link">
                <div class="content">
                    <div class="icon">
                        <div class="step-indicator" [ngClass]="{ 'active': isRouteActive('/paso1') }">1</div>
                    </div>
                </div>
            </div>
            <div class="dividing"></div>
            <div class="link">
                <div class="content">
                    <div class="icon">
                        <div class="step-indicator" [ngClass]="{ 'active': isRouteActive('/paso2') }">2</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between gap-5">
            <div class="title">Datos de Simulación</div>
            <div class="title">Simulación</div>
        </div>
    </div>
</div>