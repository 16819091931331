import { Component, OnInit, TemplateRef, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { contactService } from 'src/app/core/services/contact.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ValidatorService } from 'src/app/core/services/validator.service';
import { UserData } from 'src/app/feature/interfaces/contact.interface';

@Component({
  selector: 'app-modal-help',
  templateUrl: './modal-help.component.html',
  styleUrls: ['./modal-help.component.css']
})
export class ModalHelpComponent implements OnInit {
  toastService = inject(ToastService);
  @ViewChild('dangerTpl') dangerTpl: TemplateRef<any>;
  userData: UserData;
  form: FormGroup;
  respuestaContacto: any;
  loadingLogo = '../../../../assets/images/collapsed_logo.svg';
  isLoading = false;

  public customPatterns = {
    '0': { pattern: new RegExp('\^[0-3]?$') },
    '1': { pattern: new RegExp('\^[0-9]$') },
    '2': { pattern: new RegExp('\^[0-9kK]$') },
  };

  constructor(
    public activeModal: NgbActiveModal,
    private contactService: contactService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private vd: ValidatorService,
  ) { }

  ngOnInit(): void {
      this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(2)]],
      apellidoPaterno: [' '],
      apellidoMaterno: [' '],
      rut: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email, this.customEmailValidator]],
      telefono: ['', [Validators.required, Validators.minLength(9)]],
    });

    this.form.get('rut').valueChanges.subscribe(() => {
      this.handleInput();
    });
  }

  customEmailValidator(control) {
    const email = control.value;
    if (email && !email.includes('@') || email.indexOf('.') === -1 || email.endsWith('.')) {
      return { 'invalidEmail': true };
    }
    return null;
  }

  // Método para acceder fácilmente a los controles del formulario
  get f() {
    return this.form.controls;
  }

  handleInput() {
    const rut = this.form.get('rut').value; // Obtén el valor del campo "rut" del formulario

    if (!rut) {
      this.form.get('rut').setErrors({ 'required': true }); // Marcar el campo como requerido si no hay valor
      return;
    }

    const valiRut = this.vd.validaRut(rut); // Validar el RUT utilizando tu servicio de validación

    if (!valiRut) {
      this.form.get('rut').setErrors({ 'invalidRut': true }); // Marcar el campo como inválido si el RUT no es válido
    } else {
      this.form.get('rut').setErrors(null); // Marcar el campo como válido si el RUT es válido
    }
  }

  sendContact() {
    if (this.form.invalid) {
      return;
    } else {
      let rutVar = this.form.value.rut;
      this.userData = {
        apellidoMaterno: this.form.value.apellidoMaterno,
        apellidoPaterno: this.form.value.apellidoPaterno,
        rut: rutVar.slice(0, -1) + '-' + rutVar.slice(-1),
        telefono: this.form.value.telefono,
        tipoActualizacion: 3,
        nombre: this.form.value.name,
        email: this.form.value.email,
        tipoCoberturaAdicional: 'Cobertura Adicional',
      }
      
      this.isLoading = true;
      this.contactService.contactService(this.userData).then(data => {

        this.isLoading = false;
        this.respuestaContacto = data;

        if (this.respuestaContacto.code === 200) {

          sessionStorage.setItem('idTicket', this.respuestaContacto.idTicket);
          this.activeModal.close('success');
          
        } else if (this.respuestaContacto.code === 500){
          
          if (this.respuestaContacto.idTicket !== -1) {

            this.respuestaContacto.message = 'Error Interno';
            this.showDanger(this.dangerTpl);
            
          } else {

            this.activeModal.close('idTicket-1');
          }
          
        } else {

          this.respuestaContacto.message = 'Error Interno';
          this.showDanger(this.dangerTpl);

        }
      }).catch(error => {
        // Manejo del error
        console.error('Error en la solicitud:', error);
        // Muestra un alert o un toast con el mensaje de error
        alert('Ha ocurrido un error. Por favor, inténtalo de nuevo.');
      });
    }
  }

  
  showDanger(template: TemplateRef<any>) {
		this.toastService.show({ template, classname: 'bg-danger text-light', delay: 15000 });
	}

	ngOnDestroy(): void {
		this.toastService.clear();
	}

}
