<form [formGroup]="form" (ngSubmit)="sendContact()">
    <div class="modal-header">
        <div class="d-block">
            <h4 class="modal-title" id="modal-basic-title">¿Necesitas ayuda?</h4>
            <div class="brandline"></div>
        </div>
        <button type="button" class="close-btn" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p class="mb-5">Si tienes dudas sobre que alternativa de Seguro de Vida APV te conviene, no dudes en
            consultarnos. Estamos para ayudarte en tu meta de mejorar o anticipar tu jubilación.</p>
        <div class="row">
            <div class="col-lg-6 col-12 mb-4">
                <div class="gap-4 d-flex flex-column">
                    <!--Nombre-->
                    <div class="row g-3 d-flex align-items-center">
                        <div class="col-lg-4 col-12">
                            <label for="nombre" class="fw-semibold">Nombre:</label>
                        </div>
                        <div class="col-lg-8 col-12">
                            <div class="d-flex align-items-center gap-2">
                                <div class="d-block w-100">
                                    <input type="text" class="form-control" formControlName="name" id="name"
                                        placeholder="Nombre">
                                    <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)"
                                        class="text-danger validation-messages">
                                        <div *ngIf="f.name.errors.required">El nombre es requerido.</div>
                                        <div *ngIf="f.name.errors.minlength">El nombre debe tener al menos 2
                                            caracteres.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Nombre Fin-->
                </div>
            </div>
            <div class="col-lg-6 col-12 mb-4">
                <div class="gap-4 d-flex flex-column">
                    <!--RUT-->
                    <div class="row g-3 d-flex align-items-center">
                        <div class="col-lg-4 col-12">
                            <label for="rut" class="fw-semibold">RUT:</label>
                        </div>
                        <div class="col-lg-8 col-12">
                            <div class="d-flex align-items-center gap-2">
                                <div class="d-block w-100">
                                    <input type="text"
                                        mask="{{form.value.rut.length <=8? '1.111.111-22':'11.111.111-2'}}"
                                        [patterns]="customPatterns" [validation]="false" [dropSpecialCharacters]="true"
                                        class="form-control" formControlName="rut" id="rut" placeholder="RUT">
                                    <div *ngIf="f.rut.invalid && (f.rut.dirty || f.rut.touched)"
                                        class="text-danger validation-messages">
                                        <div *ngIf="f.rut.errors.required">El rut es requerido.</div>
                                        <div *ngIf="f.rut.errors.invalidRut">El rut tiene un formato incorrecto o no
                                            es valido.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--RUT Fin-->
                </div>
            </div>
            <!--Primer Apellido-->
            <!-- <div class="col-lg-6 col-12 mb-4">
                <div class="gap-4 d-flex flex-column">
                    <div class="row g-3 d-flex align-items-center">
                        <div class="col-lg-4 col-12">
                            <label for="apellidoPaterno" class="fw-semibold">Primer Apellido:</label>
                        </div>
                        <div class="col-lg-8 col-12">
                            <div class="d-flex align-items-center gap-2">
                                <div class="d-block w-100">
                                    <input type="text" class="form-control" formControlName="apellidoPaterno"
                                        id="apellidoPaterno" placeholder="Primer Apellido">
                                    <div *ngIf="f.apellidoPaterno.invalid && (f.apellidoPaterno.dirty || f.apellidoPaterno.touched)"
                                        class="text-danger validation-messages">
                                        <div *ngIf="f.apellidoPaterno.errors.required">El primer apellido es
                                            requerido.</div>
                                        <div *ngIf="f.apellidoPaterno.errors.minlength">El primer apellido debe
                                            tener al menos 2 caracteres.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <!--Primer Apellido Fin-->
            <!--Segundo Apellido-->
            <!-- <div class="col-lg-6 col-12 mb-4">
                <div class="gap-4 d-flex flex-column">
                    <div class="row g-3 d-flex align-items-center">
                        <div class="col-lg-4 col-12">
                            <label for="apellidoMaterno" class="fw-semibold">Segundo Apellido:</label>
                        </div>
                        <div class="col-lg-8 col-12">
                            <div class="d-flex align-items-center gap-2">
                                <div class="d-block w-100">
                                    <input type="text" class="form-control" formControlName="apellidoMaterno"
                                        id="apellidoMaterno" placeholder="Segundo Apellido">
                                    <div *ngIf="f.apellidoMaterno.invalid && (f.apellidoMaterno.dirty || f.apellidoMaterno.touched)"
                                        class="text-danger validation-messages">
                                        <div *ngIf="f.apellidoMaterno.errors.required">El segundo apellido es
                                            requerido.</div>
                                        <div *ngIf="f.apellidoMaterno.errors.minlength">El segundo apellido debe
                                            tener al menos 2 caracteres.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <!--Segundo Apellido Fin-->
            <div class="col-lg-6 col-12 mb-4">
                <div class="gap-4 d-flex flex-column">
                    <!--Correo-->
                    <div class="row g-3 d-flex align-items-center">
                        <div class="col-lg-4 col-12">
                            <label for="correo" class="fw-semibold">Correo:</label>
                        </div>
                        <div class="col-lg-8 col-12">
                            <div class="d-flex align-items-center gap-2">
                                <div class="d-block w-100">
                                    <input type="text" class="form-control" formControlName="email" id="email"
                                        placeholder="Email">
                                    <div *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)"
                                        class="text-danger validation-messages">
                                        <div *ngIf="f.email.errors.required">El email es requerido.</div>
                                        <div *ngIf="f.email.errors.email">El email no es válido.</div>
                                        <div *ngIf="f.email.errors.invalidEmail">El email no tiene un formato válido.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Correo Fin-->
                </div>
            </div>
            <div class="col-lg-6 col-12 mb-4">
                <div class="gap-4 d-flex flex-column">
                    <!--Número de Contacto-->
                    <div class="row g-3 d-flex align-items-center">
                        <div class="col-lg-4 col-12">
                            <label for="telefono" class="fw-semibold">Número de Contacto:</label>
                        </div>
                        <div class="col-lg-8 col-12">
                            <div class="d-flex align-items-center gap-2">
                                <div class="d-block w-100">
                                    <input type="text" class="form-control" formControlName="telefono" id="telefono"
                                        mask="9 9999 99 99" placeholder="9 9999 99 99">
                                    <div *ngIf="f.telefono.invalid && (f.telefono.dirty || f.telefono.touched)"
                                        class="text-danger validation-messages">
                                        <div *ngIf="f.telefono.errors.required">El teléfono es requerido.</div>
                                        <div *ngIf="f.telefono.errors.minlength">El número de teléfono debe tener al
                                            menos 9 digitos.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Número de Contacto Fin-->
                </div>
            </div>
        </div>
    </div>

    <div
        class="modal-footer d-flex flex-column-reverse flex-lg-row align-items-center justify-content-between position-relative">
        <button type="button" (click)="activeModal.dismiss('Cross click')"
            class="rounded-5 px-5 btn btn-outline-primary col-lg-auto col-12">Cerrar</button>
        <button type="submit" [disabled]="form.invalid || form.pristine || isLoading === true"
            class="rounded-5 loadingBtn px-4 px-lg-5 btn btn-green col-lg-auto col-12 mb-lg-0 mb-4 position-relative">
            <span *ngIf="!isLoading">Contactenme</span>
            <img *ngIf="isLoading" [src]="loadingLogo" class="pulseAnimation loadingLogo" />
            <span *ngIf="isLoading" class="pl-30">Enviando</span>
        </button>
    </div>
</form>

<ng-template #dangerTpl>
    <div class="d-flex gap-2 align-items-center justify-content-center">
        <i class="ri-information-2-line ri-2x"></i>
        <p class="mb-0 lh-1">{{respuestaContacto.message}}</p>
    </div>
</ng-template>

<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>